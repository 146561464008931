import {Container} from "react-bootstrap";
import React from "react";

import './App.css';

export default function Footer() {
    return (
        <footer className="footer">
            <Container fluid>
                Copyright 2021 by Pacific Diabetes Technologies. Designed by STG.
            </Container>
        </footer>
    );
}