import {makeRequest} from "./api";

export function searchUsers(query) {
    return makeRequest("/users/search?q=" + query, "GET");
}

export function getUser(userId) {
    return makeRequest("/users/" + userId, "GET");
}

export function getUserList() {
    return makeRequest("/users", "GET");
}

export function getUserSessionList(userId) {
    return makeRequest("/users/" + userId + "/sessions", "GET");
}