import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Card, Col, Form, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {forgotPassword} from "../../services/auth";

export default function ForgotPassword() {
    const [username, setUsername] = useState("");
    const [requestSent, setRequestSent] = useState(false);

    function handleSubmit(event) {
        event.preventDefault();

        if (!username || username.length < 8) {
            console.log("Invalid username");
            return;
        }

        forgotPassword(username).then(function(result) {
            if (result.success) {
                console.log("Forgot password request success");
                setRequestSent(true);
            }
        });
    }

    if (requestSent) {
        return (
            <Row>
                <Col md={{span: 4, offset: 4}}>
                    <Card className="mt-5">
                        <Card.Header>Forgot Password</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                A link and instructions to reset your password have been sent
                                to the email address associated with the account. Please check
                                your email to continue.
                            </Card.Text>
                            <Card.Text>
                                <Link to="/login">Back to Login</Link>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        );
    }

    return (
        <Row>
            <Col md={{span: 4, offset: 4}}>
                <Card className="mt-5">
                    <Card.Header>Forgot Password</Card.Header>
                    <Card.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="forgotPasswordFormUsername">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={username}
                                    onChange={e => setUsername(e.target.value)}/>
                            </Form.Group>
                            <Button className="mb-3" variant="primary" type="submit">
                                Continue
                            </Button>
                        </Form>
                        <Card.Text>
                            <Link to="/login">Back to Login</Link>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}