import {Outlet} from "react-router-dom";
import NavBar from "./NavBar";
import {Container} from "react-bootstrap";
import React from "react";

export default function Chrome({ currentUser }) {
    return (
        <div className="App">
            <div className="wrap">
                <NavBar currentUser={currentUser}/>
                <Container fluid>
                    <Outlet/>
                </Container>
            </div>
            <footer className="footer">
                <Container fluid>
                    Copyright 2021 by Pacific Diabetes Technologies. Designed by STG.
                </Container>
            </footer>
        </div>
    );
}