import {refresh} from "./auth";

const BASE_URL = process.env.REACT_APP_CGMAPI_URL;

const getToken = () => {
    return localStorage.getItem("token");
};

const getRefreshToken = () => {
    return localStorage.getItem("refresh_token");
}

const setToken = (token) => {
    localStorage.setItem("token", token);
};

const setRefreshToken = (refreshToken) => {
    localStorage.setItem("refreshToken", refreshToken);
};

export function makeRequest(path, method, body, refresh_token) {
    const urlPath = BASE_URL + path;
    const params = {
        method: method,
        headers: {
            "Authorization": "Bearer " + (refresh_token ? getRefreshToken() : getToken()),
            "Content-Type": "application/json"
        },
        body: body ? JSON.stringify(body) : null
    };

    return fetch(urlPath, params).then(async function(response) {
        if (response.status === 401) {
            let result = await response.json();

            // if refresh_token is true, it prevents an endless loop when refreshing the token if the
            // refresh token is also expired
            if (result.reason === "TOKEN_EXPIRED" && refresh_token !== true) {
                console.log("Token has expired, sending refresh token request...");
                result = await refresh();

                if (result.success) {
                    setToken(result.token);
                    return makeRequest(path, method, body);
                }
                else {
                    console.log("Refresh failed");
                }
            }

            console.log("Setting tokens to null and refreshing");
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            window.location.reload();
            return result;
        }
        else if (response.ok) {
            return response.json();
        }
    });
}