import {library} from "@fortawesome/fontawesome-svg-core";
import {faRedo} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {
    ButtonGroup,
    ButtonToolbar,
    Card,
    Col,
    Row,
    Table,
} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {useParams} from "react-router-dom";
import {getSession, getSessionData, getSessionEvents} from "../../services/session";
import SessionDownload from "./SessionDownload";
import {SessionDeleteButton} from "./SessionDelete";

library.add(faRedo);

function EventListItem(props) {
    const event = props.event;

    return (
        <tr>
            <td key={'eventDate'}>{new Date(Date.parse(event.time)).toLocaleString()}</td>
            <td key={'eventType'}>{event.event_type}</td>
            <td key={'eventValue'}>{event.value}</td>
            <td key={'eventDescription'}>{event.description}</td>
        </tr>
    );
}

function EventListTable(props) {
    const events = props.events;
    const eventTableItems = events.map(function(item) {
        return (<EventListItem key={item.event_id} event={item}/>);
    });

    return (
        <Table className="table-striped">
            <thead>
            <tr>
                <th>Time</th>
                <th>Event Type</th>
                <th>Event Value</th>
                <th>Description</th>
            </tr>
            </thead>
            <tbody>
            {eventTableItems}
            </tbody>
        </Table>
    );
}

function SessionInfo(props) {
    const session = props.session;
    const events = props.events || [];
    const data = props.data || [];
    const refresh = props.refresh || function(){};

    if (session != null) {
        return (
            <div className="sessionDetail">
                {session && session.ended != null &&
                    <SessionDeleteButton session={session}/>
                }
                <h1 className="mb-3">Session started {new Date(Date.parse(session.started)).toLocaleString()}</h1>

                <Row>
                    <Col md={{span: 12}}>
                        <Card className="mb-3" bg="light" text="dark">
                            <Card.Header>Session Summary</Card.Header>
                            <Card.Body>
                                <dl className="row mb-0">
                                    <dt className="col-sm-2">Patient Name</dt>
                                    <dd className="col-sm-10">{session.user.last_name}, {session.user.first_name}</dd>

                                    <dt className="col-sm-2">Session ID</dt>
                                    <dd className="col-sm-4"><code>{session.session_id.substring(24).toUpperCase()}</code></dd>

                                    <dt className="col-sm-2">Hardware Version</dt>
                                    <dd className="col-sm-4">{session.hardware_version}</dd>

                                    <dt className="col-sm-2">Device ID</dt>
                                    <dd className="col-sm-4"><code>{session.device_id}</code></dd>

                                    <dt className="col-sm-2">Firmware Version</dt>
                                    <dd className="col-sm-4">{session.firmware_version}</dd>

                                    <dt className="col-sm-2">Sensor ID</dt>
                                    <dd className="col-sm-4"><code>{session.sensor_id}</code></dd>

                                    <dt className="col-sm-2">App Version</dt>
                                    <dd className="col-sm-4">{session.software_version}</dd>
                                </dl>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col md={{span: 12}}>
                        <Card className="mb-3">
                            <Card.Header>Session Data</Card.Header>
                            <Card.Body>
                                <ButtonToolbar>
                                    <ButtonGroup className="me-2">
                                        <Button onClick={refresh}><FontAwesomeIcon icon="redo"/></Button>
                                    </ButtonGroup>
                                    <ButtonGroup className="me-2">
                                        <SessionDownload sessionId={session.session_id}/>
                                    </ButtonGroup>
                                </ButtonToolbar>
                                <ResponsiveContainer height={500}>
                                    <LineChart
                                        data={data}
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            bottom: 20,
                                            left: 20,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="0"
                                            type="number"
                                            scale="time"
                                            interval="preserveStartEnd"
                                            minTickGap={125}
                                            tickCount={5}
                                            tickFormatter={(timestamp) => new Date(timestamp).toLocaleString()}
                                            domain={['auto', 'auto']}
                                            label={{value: "Time of Measurement", position: "bottom"}}
                                            name="Time" />
                                        <YAxis
                                            dataKey="1"
                                            yAxisId="left"
                                            axisLine="false"
                                            label={{value: "Glucose Concentration (mg/dL)", angle: -90, position: "insideLeft"}} />
                                        <YAxis
                                            dataKey="2"
                                            yAxisId="right"
                                            axisLine="false"
                                            label={{value: "Current (nA)", angle: -90, position: "insideRight"}}
                                            name="Current (nA)"
                                            orientation="right" />
                                        <Tooltip labelFormatter={(timestamp) => new Date(timestamp).toLocaleString()} />
                                        <Legend layout="horizontal" verticalAlign="top" align="center" height={36} />
                                        {
                                            events.map((event) => {
                                                return (
                                                    <ReferenceLine
                                                        x={new Date(Date.parse(event.time)).getTime()}
                                                        xAxisId="0"
                                                        yAxisId="left"
                                                        stroke="green"
                                                        label={{
                                                            position: "left",
                                                            value: event.event_type,
                                                            angle: -90,
                                                        }}
                                                        key={event.event_id}
                                                    />
                                                );
                                            })
                                        }
                                        <Line
                                            type="linear"
                                            yAxisId="left"
                                            dataKey="1"
                                            name="Glucose Concentration (mg/dL)"
                                            dot={false}
                                            stroke="#001970" />
                                        <Line
                                            type="linear"
                                            yAxisId="right"
                                            dataKey="2"
                                            name="Current (nA)"
                                            dot={false}
                                            stroke="#c66900" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col md={{span: 6}}>
                        <Card className="mb-3" bg="light" text="dark">
                            <Card.Header>Battery Analysis</Card.Header>
                            <Card.Body>
                                <ResponsiveContainer height={500}>
                                    <LineChart
                                        data={data}
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            bottom: 20,
                                            left: 20,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="0"
                                            type="number"
                                            scale="time"
                                            interval="preserveStartEnd"
                                            minTickGap={125}
                                            tickCount={5}
                                            tickFormatter={(timestamp) => new Date(timestamp).toLocaleString()}
                                            domain={['auto', 'auto']}
                                            label={{value: "Time of Measurement", position: "bottom"}}
                                            name="Time" />
                                        <YAxis
                                            dataKey="3"
                                            yAxisId="left"
                                            axisLine="false"
                                            label={{value: "Battery Level (%)", angle: -90, position: "insideLeft"}} />
                                        <Tooltip labelFormatter={(timestamp) => new Date(timestamp).toLocaleString()} />
                                        <Legend layout="horizontal" verticalAlign="top" align="center" height={36} />
                                        <Line
                                            type="linear"
                                            yAxisId="left"
                                            dataKey="3"
                                            name="Battery Level (%)"
                                            dot={false}
                                            stroke="#001970" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={{span: 6}}>
                        <Card bg="light" text="dark">
                            <Card.Header>VRef Profile</Card.Header>
                            <Card.Body>
                                <ResponsiveContainer height={500}>
                                    <LineChart
                                        data={data}
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            bottom: 20,
                                            left: 20,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="0"
                                            type="number"
                                            scale="time"
                                            interval="preserveStartEnd"
                                            minTickGap={125}
                                            tickCount={5}
                                            tickFormatter={(timestamp) => new Date(timestamp).toLocaleString()}
                                            domain={['auto', 'auto']}
                                            label={{value: "Time of Measurement", position: "bottom"}}
                                            name="Time" />
                                        <YAxis
                                            dataKey="4"
                                            yAxisId="left"
                                            axisLine="false"
                                            label={{value: "VRef Read-back (mV)", angle: -90, position: "insideLeft"}}
                                            name="VRef Read-back (mV)" />
                                        <YAxis
                                            dataKey="5"
                                            yAxisId="right"
                                            axisLine="false"
                                            label={{value: "VRef Set Point (mV)", angle: -90, position: "insideRight"}}
                                            name="VRef Set Point (mV)"
                                            orientation="right" />
                                        <Tooltip labelFormatter={(timestamp) => new Date(timestamp).toLocaleString()} />
                                        <Legend layout="horizontal" verticalAlign="top" align="center" height={36} />
                                        <Line
                                            type="linear"
                                            yAxisId="left"
                                            dataKey="4"
                                            name="VRef Read-back (mV)"
                                            dot={false}
                                            stroke="#001970" />
                                        <Line
                                            type="linear"
                                            yAxisId="right"
                                            dataKey="5"
                                            name="VRef Set Point (mV)"
                                            dot={false}
                                            stroke="#c66900" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Card className="mb-3" bg="light" text="dark">
                    <Card.Header>Events</Card.Header>
                    <Card.Body>
                        <EventListTable events={events}/>
                    </Card.Body>
                </Card>
            </div>
        );
    }
    else {
        return (
            <div className="sessionDetail">Loading...</div>
        );
    }
}

export default function SessionDetail({ currentUser }) {
    let { sessionId } = useParams();
    const [session, setSession] = useState(null);
    const [measurements, setMeasurements] = useState([]);
    const [events, setEvents] = useState([]);

    async function loadSession(sessionId) {
        getSession(sessionId).then(function(getSessionResult) {
            if (getSessionResult.success) {
                setSession(getSessionResult.session);
                getSessionEvents(sessionId).then(function(getEventsResult) {
                    if (getEventsResult.success) {
                        setEvents(getEventsResult.events);
                        getSessionData(sessionId).then(function(getDataResult) {
                            if (getDataResult.success) {
                                setMeasurements(getDataResult.measurements);
                            }
                        });
                    }
                });
            }
        });
    }

    useEffect(() => {
        const interval = setInterval(function() {
            loadSession(sessionId);
        }, (5 * 60 * 1000)); // every five minutes

        loadSession(sessionId);

        return function() {
            clearInterval(interval);
        };
    }, [sessionId]);

    return (
        <SessionInfo
            session={session}
            data={measurements}
            events={events}
            currentUser={currentUser}
            refresh={function(){
                loadSession(sessionId);
            }}/>
    );
}