import React, {useEffect, useState} from 'react';
import {Col, Row, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {getUserList} from "../../services/user";

function UserListItem({ user }) {
    return (
        <tr>
            <td>
                <Link to={"/users/" + user.user_id}>{user.last_name}, {user.first_name}</Link>
            </td>
            <td>{user.username}</td>
            <td>{user.email}</td>
            <td>{new Date(Date.parse(user.created)).toLocaleString()}</td>
        </tr>
    );
}

export default function UserList({ currentUser }) {
    const [userList, setUserList] = useState([]);

    useEffect(function() {
        getUserList().then(function(result) {
            if (result.success) {
                console.log("Got user list");
                console.log(result.users);
                setUserList(result.users);
            }
        });
    }, []);

    const userListItems = userList.map(function(user) {
        return (<UserListItem key={user.user_id} user={user}/>);
    });

    return (
        <Row>
            <Col>
                <h1>Users</h1>
                <Table className="table-striped">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Username</th>
                        <th>Email Address</th>
                        <th>Created</th>
                    </tr>
                    </thead>
                    <tbody>
                    {userListItems}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}