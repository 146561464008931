import React, { useState } from 'react';
import {Alert, Card, Col, Form, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useNavigate, useSearchParams} from "react-router-dom";
import {resendConfirmationCode} from "../../services/auth";

async function confirmSignUpRequest(params) {
    return fetch(process.env.REACT_APP_CGMAPI_URL + "/auth/confirm", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(params)
    }).then(function(response) {
        return response.json();
    });
}

function ConfirmationCodeResentAlert({ show }) {
    if (show) {
        return (
            <Alert variant="success">
                Code sent. Please check your email.
            </Alert>
        );
    }
    else {
        return <></>
    }
}

export default function ConfirmSignUp() {
    const [confirmationCode, setConfirmationCode] = useState(null);
    const [searchParams,] = useSearchParams();
    const [showAlert, setShowAlert] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async function(event) {
        event.preventDefault();
        const username = searchParams.get("username");
        console.log("Sending confirm request: username=" + username + " code=" + confirmationCode);
        const response = await confirmSignUpRequest({
            username: username,
            code: confirmationCode
        });

        if (response.success) {
            console.log("Account confirmed");
            navigate("/");
        }
    };

    function handleResendCode(event) {
        event.preventDefault();
        const username = searchParams.get("username");
        resendConfirmationCode(username).then(function(result) {
            if (result.success) {
                setShowAlert(true);
                // TODO: show that the code was re-sent
            }
        });
    }

    return (
        <Row>
            <Col md={{span: 4, offset: 4}}>
                <Card className="mt-5">
                    <Card.Header>Confirm Account</Card.Header>
                    <Card.Body>
                        <Card.Text>A confirmation code has been sent to your email address. Enter
                        the code to complete the sign-up process.</Card.Text>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="confirmSignUpFormCode">
                                <Form.Label>Confirmation Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Confirmation Code"
                                    onChange={e => setConfirmationCode(e.target.value)}/>
                            </Form.Group>
                            <Button className="mb-3" variant="primary" type="submit">
                                Confirm Account
                            </Button>
                        </Form>
                        <Card.Text>
                            Didn't get an email? <a href="/resend_confirmation" onClick={handleResendCode}>Re-send email</a>
                        </Card.Text>
                        <ConfirmationCodeResentAlert show={showAlert}/>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}