import {makeRequest} from "./api";

export function getSessionList(user_id) {
    let path = "/cgm/sessions";
    if (user_id != null) {
        path += "?user_id=" + user_id;
    }

    return makeRequest(path, "GET");
}

export function getSession(sessionId) {
    return makeRequest("/cgm/sessions/" + sessionId, "GET");
}

export function getSessionData(sessionId) {
    return makeRequest("/cgm/measurements/" + sessionId, "GET");
}

export function getSessionEvents(sessionId) {
    return makeRequest("/cgm/events/" + sessionId, "GET");
}

export function prepareDownloadLink(sessionId) {
    return makeRequest("/cgm/measurements/" + sessionId + "/prepare_download", "GET");
}

export function deleteSession(sessionId) {
    return makeRequest("/cgm/sessions/" + sessionId, "DELETE");
}