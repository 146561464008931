import React, {useEffect, useState} from "react";
import {Card, Col, Form, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {resetPassword} from "../../services/auth";
import {useNavigate, useSearchParams} from "react-router-dom";

export default function ResetPassword() {
    const [passwd, setPasswd] = useState("");
    const [passwdRepeat, setPasswdRepeat] = useState("");
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(function() {
        const token = searchParams.get("token");
        if (!token || token.length === 0) {
            navigate("/login");
        }
        console.log("Reset token: " + token);
    }, [navigate, searchParams]);

    function handleSubmit(event) {
        event.preventDefault();

        if (passwd !== passwdRepeat) {
            console.log("Passwords don't match!");
            return;
        }

        const token = searchParams.get("token");
        if (!token) {
            console.log("No reset password token!");
            navigate("/login");
        }
        else {
            resetPassword(token, passwd).then(function(result) {
                if (result.success) {
                    console.log("Password has been reset!");
                    navigate("/login");
                }
            });
        }
    }

    return (
        <Row>
            <Col md={{span: 4, offset: 4}}>
                <Card className="mt-5">
                    <Card.Header>Reset Password</Card.Header>
                    <Card.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="signUpFormPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    value={passwd}
                                    onChange={e => setPasswd(e.target.value)}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="signUpFormPasswordRepeat">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    value={passwdRepeat}
                                    onChange={e => setPasswdRepeat(e.target.value)}/>
                            </Form.Group>
                            <Button className="mb-3" variant="primary" type="submit">
                                Reset Password
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}