import { useState } from 'react';

export function getToken() {
    return localStorage.getItem("token");
}

export default function useToken() {
    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem("token", userToken);
        setToken(userToken);
    }

    return {
        setToken: saveToken,
        token
    }
}