import {useNavigate} from "react-router-dom";
import {deleteSession} from "../../services/session";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import {Modal} from "react-bootstrap";

export function SessionDeleteButton({ session }) {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function handleDelete() {
        deleteSession(session.session_id).then(function(result) {
            if (result.success) {
                console.log("Session deleted");
                navigate("/sessions");
            }
        });
    }

    return (
        <>
            <Button className="float-end" variant="danger" onClick={handleShow}>Delete Session</Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Session</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you really want to delete this session? This action cannot be undone.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDelete}>Delete Session</Button>
                    <Button variant="primary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>


    );
}