import {Card, Col, Form, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";

function UserProfileTextEdit({ value, setValue, label, id, type }) {
    return (
        <Form.Group as={Row} className="mb-3" controlId={id}>
            <Form.Label column sm={2}>
                {label}
            </Form.Label>
            <Col sm={10}>
                <Form.Control
                    type={type}
                    placeholder={label}
                    value={value}
                    onChange={e => setValue(e.target.value)}/>
            </Col>
        </Form.Group>
    );
}

export default function UserProfile({ currentUser }) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");

    useEffect(function() {
        if (currentUser.user_id) {
            setFirstName(currentUser.first_name);
            setLastName(currentUser.last_name);
            setUsername(currentUser.username);
            setEmail(currentUser.email);
        }
    }, [currentUser]);

    if (currentUser) {
        return (
            <>
                <Row>
                    <Col>
                        <h1>{currentUser.last_name}, {currentUser.first_name}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="mb-3" bg="light" text="dark">
                            <Card.Header>User Profile</Card.Header>
                            <Card.Body>
                                <Card.Text>Account created {new Date(Date.parse(currentUser.created)).toLocaleString()}</Card.Text>
                                <Form>
                                    <UserProfileTextEdit
                                        value={firstName}
                                        setValue={setFirstName}
                                        id="formUserProfileFirstName"
                                        type="text"
                                        label="First Name"/>
                                    <UserProfileTextEdit
                                        value={lastName}
                                        setValue={setLastName}
                                        id="formUserProfileLastName"
                                        type="text"
                                        label="Last Name"/>
                                    <UserProfileTextEdit
                                        value={username}
                                        setValue={setUsername}
                                        id="formUserProfileUsername"
                                        type="text"
                                        label="Username"/>
                                    <UserProfileTextEdit
                                        value={email}
                                        setValue={setEmail}
                                        id="formUserProfileEmail"
                                        type="text"
                                        label="Email"/>
                                    <Form.Group as={Row} className="mb-3">
                                        <Col sm={{span: 10, offset: 2}}>
                                            <Button type="submit">Save</Button>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                        <Card className="mb-3" bg="light" text="dark">
                            <Card.Header>Change Password</Card.Header>
                            <Card.Body>
                                <Form>
                                    <UserProfileTextEdit
                                        value={password}
                                        setValue={setPassword}
                                        id="formUserProfilePassword"
                                        label="Password"
                                        type="password"/>
                                    <UserProfileTextEdit
                                        value={passwordRepeat}
                                        setValue={setPasswordRepeat}
                                        id="formUserProfilePasswordRepeat"
                                        label="Password (Confirm)"
                                        type="password"/>
                                    <Form.Group as={Row} className="mb-3">
                                        <Col sm={{span: 10, offset: 2}}>
                                            <Button type="submit">Change Password</Button>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <Row>
            <Col>
                <h1>Loading...</h1>
            </Col>
        </Row>
    );
}
