import {makeRequest} from "./api";

const BASE_URL = process.env.REACT_APP_CGMAPI_URL;

const getToken = () => {
    return localStorage.getItem("token");
};

export function login(username, password) {
    return fetch(BASE_URL + "/auth/login", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ username, password })
    }).then(function(response) {
        return response.json();
    });
}

export function refresh() {
    return makeRequest("/auth/refresh", "POST", null, true);
}

export function getAuth() {
    return makeRequest("/auth", "GET");
}

export function resendConfirmationCode(username) {
    return fetch(BASE_URL + "/auth/resend_confirmation", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({"username": username})
    }).then(function(response) {
        return response.json();
    });
}

export function forgotPassword(username) {
    return fetch(BASE_URL + "/auth/forgot_password", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            username: username
        })
    }).then(function(response) {
        return response.json();
    });
}

export function resetPassword(token, password) {
    return fetch(BASE_URL + "/auth/reset_password", {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + getToken()
        },
        body: JSON.stringify({
            token: token,
            password: password
        })
    }).then(function(response) {
        return response.json();
    });
}