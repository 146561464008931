import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Container} from "react-bootstrap";
import useToken from "../services/useToken";

import './App.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Chrome from "./Chrome";
import Footer from "./Footer"

import UserList from "./User/UserList";
import UserProfile from "./User/UserProfile";

import Login from "./Login/Login"
import SignUp from "./Login/SignUp"
import ConfirmSignUp from "./Login/ConfirmSignUp";
import ResetPassword from "./Login/ResetPassword";
import ForgotPassword from "./Login/ForgotPassword";

import Sessions from "./Session/SessionList";
import SessionDetail from "./Session/SessionDetail";
import {getAuth} from "../services/auth";
import {UserDetail} from "./User/UserDetail";
import useRefreshToken from "../services/useRefreshToken";

function App() {
    const [currentUser, setCurrentUser] = useState({});
    const {token, setToken} = useToken();
    const {setRefreshToken} = useRefreshToken();

    useEffect(function() {
        if (token == null) return;

        console.log(process.env);

        getAuth().then(function(result) {
            if (result.success) {
                console.log("Got user " + result.user.username);
                setCurrentUser(result.user);
            }
        });
    }, [token]);

    if (!token) {
        return (
            <div className="App">
                <div className="wrap">
                    <Container fluid>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/signup" element={<SignUp/>}/>
                                <Route path="/confirm" element={<ConfirmSignUp/>}/>
                                <Route path="/reset_password" element={<ResetPassword/>}/>
                                <Route path="/forgot_password" element={<ForgotPassword/>}/>
                                <Route path="*" element={<Login setToken={setToken} setRefreshToken={setRefreshToken}/>}/>
                            </Routes>
                        </BrowserRouter>
                    </Container>
                </div>
                <Footer/>
            </div>
        );
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Chrome currentUser={currentUser}/>}>
                    <Route path="/sessions/:sessionId" element={<SessionDetail currentUser={currentUser}/>}/>
                    <Route path="/sessions" element={<Sessions currentUser={currentUser} forUser={{}}/>}/>
                    <Route path="/users/:userId" element={<UserDetail currentUser={currentUser}/>}/>
                    <Route path="/users" element={<UserList currentUser={currentUser}/>}/>
                    <Route path="/profile" element={<UserProfile currentUser={currentUser}/>}/>
                    <Route path="*" element={
                        <h1>Not Found</h1>
                    }/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;

export async function getStaticProps() {
    return {
        props: {
            isPassedToWithAuthenticator: true,
        },
    };
}