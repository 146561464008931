import UserProfile from "./UserProfile";
import {useEffect, useState} from "react";
import {getUser} from "../../services/user";
import {useParams} from "react-router-dom";
import Sessions from "../Session/SessionList";

export function UserDetail({ currentUser }) {
    const [user, setUser] = useState({});
    const {userId} = useParams();

    useEffect(function() {
        getUser(userId).then(function(result) {
            if (result.success) {
                console.log("Got user for user detail");
                setUser(result.user);
            }
        });
    }, [userId]);

    return (
        <>
            <UserProfile currentUser={user}/>
            <Sessions currentUser={currentUser} showSearch={false} forUser={user}/>
        </>
    );
}