import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Alert, Card, Col, Form, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Link, useNavigate} from "react-router-dom";
import {login} from "../../services/auth";

async function loginRequest(credentials) {
    return fetch(process.env.REACT_APP_CGMAPI_URL + "/auth/login", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(credentials)
    }).then(function(response) {
        return response.json();
    });
}

export default function Login({ setToken, setRefreshToken }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const handleSubmit = function(event) {
        event.preventDefault();

        login(username, password).then(function(result) {
            if (result.success) {
                console.log("User login success");
                setToken(result.token);
                setRefreshToken(result.refresh_token);
            }
            else {
                if (result.reason === "USER_NOT_CONFIRMED") {
                    console.log("Account confirmation required");
                    navigate("/confirm?username=" + username);
                }
                else if (result.reason === "INVALID_CREDENTIALS") {
                    console.log("Invalid credentials: username or password incorrect");
                    setErrorMessage("Could not find account. Please try again.");
                    setShowError(true);
                }
            }
        });
    };

    return (
        <Row>
            <Col md={{span: 4, offset: 4}}>
                <Card className="mt-5">
                    <Card.Header>Log In</Card.Header>
                    <Card.Body>
                        {showError &&
                            <Alert variant="danger">Login failed.</Alert>
                        }
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="loginFormUsername">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Username"
                                    onChange={e => setUsername(e.target.value)}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="loginFormPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Password"
                                    onChange={e => setPassword(e.target.value)}/>
                            </Form.Group>
                            <Button className="mb-3" variant="primary" type="submit">
                                Log In
                            </Button>
                        </Form>
                        <Card.Text>
                            Don't have an account? <Link to="/signup">Sign Up</Link>
                            <br/>
                            <Link to="/forgot_password">Forgot Password?</Link>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};