import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import {prepareDownloadLink} from "../../services/session";

export default function SessionDownload({ sessionId }) {
    const [show, setShow] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState("");

    const handleClose = () => setShow(false);

    function handleDownload() {
        prepareDownloadLink(sessionId).then(function(result) {
            if (result.success) {
                setDownloadUrl(process.env.REACT_APP_CGMAPI_URL + result.download_url);
                setShow(true);
            }
        });
    }

    return (
        <>
            <Button variant="primary" onClick={handleDownload}>
                Download Excel Spreadsheet
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Download Excel Spreadsheet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><a target="_blank" rel="noreferrer" onClick={handleClose} href={downloadUrl}>Download File</a></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}