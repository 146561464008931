import React, {useState} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {Link, useNavigate} from 'react-router-dom';
import Button from "react-bootstrap/Button";

async function signUpRequest(attributes) {
    return fetch(process.env.REACT_APP_CGMAPI_URL + "/auth/signup", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(attributes)
    }).then(function(response) {
        return response.json();
    });
}

export default function SignUp() {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [birthdate, setBirthDate] = useState("");
    const [gender, setGender] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async function(event) {
        event.preventDefault();
        const response = await signUpRequest({
            first_name: firstName,
            last_name: lastName,
            email: emailAddress,
            birthdate: birthdate,
            gender: gender,
            username: username,
            password: password
        });

        if (response.success) {
            console.log("Account creation success");
            navigate("/confirm?username=" + username);
        }
    };

    return (
        <Row>
            <Col md={{span: 4, offset: 4}}>
                <Card className="mt-5">
                    <Card.Header>Sign Up</Card.Header>
                    <Card.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="signUpFormFirstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="First Name"
                                    onChange={e => setFirstName(e.target.value)}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="signUpFormLastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Last Name"
                                    onChange={e => setLastName(e.target.value)}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="signUpFormEmail">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Email Address"
                                    onChange={e => setEmailAddress(e.target.value)}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="signUpFormBirthDate">
                                <Form.Label>Birthdate</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="YYYY-MM-DD"
                                    onChange={e => setBirthDate(e.target.value)}/>
                            </Form.Group>
                            <Form.Group className="mb-5" controlId="signUpFormGender">
                                <Form.Label>Gender</Form.Label>
                                <Form.Select
                                    onChange={e => setGender(e.target.value)}>
                                    <option>Select One</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="nonbinary">Non-Binary</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="signUpFormUsername">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Username"
                                    onChange={e => setUsername(e.target.value)}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="signUpFormPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Password"
                                    onChange={e => setPassword(e.target.value)}/>
                            </Form.Group>
                            <Button className="mb-3" variant="primary" type="submit">
                                Sign Up
                            </Button>
                        </Form>
                        <Card.Text>
                            Already signed up? <Link to="/">Log In</Link>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}