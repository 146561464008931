import { useState } from 'react';

export default function useRefreshToken() {
    const getRefreshToken = () => {
        return localStorage.getItem("refresh_token");
    };

    const [refreshToken, setRefreshToken] = useState(getRefreshToken());

    const saveRefreshToken = refreshToken => {
        localStorage.setItem("refresh_token", refreshToken);
        setRefreshToken(refreshToken);
    }

    return {
        setRefreshToken: saveRefreshToken,
        refreshToken
    }
}